import React, { useEffect } from "react"

import Prism from "prismjs"

// Components
import Layout from "../components/layout"
import Band from "../components/band"
import SEO from "../components/seo"

// Styles
import grid from "../shared/bootstrap-grid.module.css"

export default function BlogPostTemplate({
  pageContext
}) {
  const { html, title } = pageContext;

  useEffect(() => {
    // call the highlightAll() function to style our code blocks
    Prism.highlightAll()
  }, []);

  return (
    <Layout>
      <SEO title={title} keywords={[
        `tigerface`, `systems`, `salesforce`, `products`, `lwc`,
        `lwc component testing`, `lwc nested components`,
        `lwc mock component`, `lwc jest test`, `${title}`]} />

      <Band>
        <h1>{title}</h1>
        <div
          className={`${grid.mtLg0} ${grid.mt4}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />

        {/* comments app goes here */}
      </Band>
    </Layout>
  )
}
